<template>
  <div class="success-tip">
    <div class="icon-tip">
      <i></i>
    </div>
    <h3>{{ $route.params.name === '1' ? '签署': '认证' }}成功</h3>
  </div>
</template>

<script>
export default {
  name: 'Succee'
}
</script>

<style scoped>
.success-tip {
  padding: 1.5rem 1rem;
  text-align: center;
}
.icon-tip {
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 auto;
  line-height: 1.4rem;
  background: #3db9dc;
}
.icon-tip i {
  height: 0.6rem;
  width: 0.4rem;
  margin-top: 0.2rem;
  -webkit-transform: rotate(39deg);
  transform: rotate(39deg);
  display: inline-block;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  color: #fff;
}
.success-tip h3 {
  font-size: .3rem;
  font-weight: 400;
  margin: 0.3rem 0 0.2rem;
}
</style>
